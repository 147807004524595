
/**
 * CARD layout design
 * Ref: https://dev.to/prvnbist/css-grid-cards-layout-aspect-ratio-45ni
 */

/*
1. 1:1 - 100%
2. 16:9 - 56.25%
3. 4:3 - 75%
4. 3:2 - 66.66%
5. 8:5 - 62.5%
*/

@media screen and (min-width: 420px) {

  ul.cardGrid {
    margin: 10px;
    display: grid;
    grid-gap:16px;
    padding-inline-start: 5px;
    /*
    Minimum Width = 368px
    Maximum Width = 1fr i.e full width
    A card will wrap to next row if the browser can not fit cards with their minimum width
    
    auto-fill - this will try to fill all the columns or leave an empty columns if there aren't enough cards
    */
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }

  ul.cardGrid li {
    background: rgb(2,0,36);
    background: linear-gradient(330deg, rgba(2,0,36,0.8911939775910365) 0%, rgba(87,161,149,1) 52%, rgba(0,212,255,1) 100%);
    padding-top: 56.25%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  ul.cardGrid.zoomin li {
      transition: transform 0.6s;
  }
  
  ul.cardGrid.zoomin li:hover {
    /* padding: 2px; */
    /* border-radius: 5px; */
    transform: scale(1.2); 
    transition: transform 0.3s;
    z-index: 4;
  }
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

  .owner-account-card {
      border: 4px solid #103f6d;
      /* padding: 2px; */
  }
  /* .owner-account-card > li:hover {
    border: 6px solid #103f6d;
    padding: 2px;
  } */

  .shared-account-card {
      border: 4px solid brown;
      /* padding: 2px; */
  }
  /* .shared-account-card > li:hover {
    border: 6px solid white;
    padding: 2px;
    transform: scale(1.2); 
    transition: transform 0.6s;
  } */

  .account-filter-icon.active {
      border: 2px solid blue;
      /* padding: 1px; */
  }

  .archive-account {
    opacity: 0.7;
  }

  li.CHIT > img, li.SUB > img {
    padding: 10px;
  }

}


  ul.cardGrid li.CC {
    background: rgb(152,69,136);
    background: linear-gradient(330deg, rgba(152,69,136,0.8911939775910365) 0%, rgba(164,181,171,1) 52%, rgba(255,64,0,1) 100%);
  }

  ul.cardGrid li.FRN {
    background: rgb(81, 69, 152);
    background: linear-gradient(330deg, rgba(69, 119, 152, 0.891) 0%, rgb(4, 0, 180) 10%, rgb(179, 181, 164) 52%);
  }

  ul.cardGrid li img {
    top: 8%;
    left: 48%;
    width: 54%;
    position: absolute;
    opacity: 0.4;
  }

  ul.cardGrid li.UTIL img { width: 46%; opacity: 0.5; }
  ul.cardGrid li.CHIT img { width: 52%; }

  ul.cardGrid li span.type {
    top: 62%;
    left: 4%;
    opacity: 1.0;
    position: absolute;
    color: white;
    font-size: 12px;
  }

  ul.cardGrid li span.bal {
    top: 78%;
    left: 4%;
    position: absolute;
    color: brown;
    padding: 1px;
    background-color: blanchedalmond;
    font-size: 13px;
    font-stretch: ultra-expanded;
  }
  
  ul.cardGrid li span.bal.credit-bal {
    color: darkblue;
    background-color: honeydew;
  }
  
  ul.cardGrid li span.code {
    top: 64%;
    left: 4%;
    position: absolute;
    direction: rtl;
    float: right;
    font-size: small;
    font-weight: 400;
    opacity: 0%;
  }
  
  ul.cardGrid li span.name {
    top: 1%;
    left: 4%;
    position: absolute;
    color: #000000;
    font-size: 16px;
    font-stretch: condensed;
    line-height: 1.1;
  }

  ul.cardGrid li span.desc {
    top: 84%;
    left: 4%;
    position: absolute;
    color: #000000;
    font-size: small;
    font-stretch: condensed;
  }

  ul.cardGrid li span.updated-at {
    top: 0.2%;
    left: 42%;
    width: fit-content;
    position: absolute;
    color: rgb(0, 0, 0);
    padding: 1px;
    font-size: 12px;
    font-stretch: condensed;
  }

  ul.cardGrid li span.action-edit {
    top: 30%;
    left: 4%;
    position: absolute;
    cursor: pointer;
  }
  
  ul.cardGrid li span.action-edit span {
    top: 86%;
    left: 10%;
    position: absolute;
    cursor: pointer;
    font-size: x-small;
  }

  ul.cardGrid li span.action-view {
    top: 26%;
    left: 22%;
    position: absolute;
    cursor: pointer;
  }  

  ul.cardGrid li span.action-view span {
    top: 74%;
    left: -16%;
    position: absolute;
    cursor: pointer;
    font-size: x-small;
  }

  ul.cardGrid li span.shared-icon {
    top: 78%;
    left: 84%;
    color: white;
    position: absolute;
    cursor: pointer;
  }

  li.CC span.type {
    top: 40%;
    left: 30%;
    position: absolute;
    color: white;
    font-size: large;
  } */

  ul.cardGrid li.CC {
    width: 100%;
    background-size: contain;
  }